@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,500&display=swap');

/* @font-face { 
    font-family: Twemoji;
    src: url("https://cdn.jsdelivr.net/npm/twemoji-colr-font@14.1.3/twemoji.woff2") format("woff2");
    font-display: swap;
} */

:root {
    --shadow-color: 0deg 0% 0%;
    --shadow: .10px .5px 2.7px hsl(var(--shadow-color)/.14), .4px .8px 1px -3.2px hsl(var(--shadow-color)/.14), 1px 2px 2.5px -4.5px hsl(var(--shadow-color)/.14);
    --default-background-color: #EEEEEE;
    --dark-background-color: #222222;
    --sans-serif-font: "Nunito Sans";
    --monospaced-font: "Courier Prime";
}

body {
    background-color: #E1E1E1 !important;
}

html,
body {
    touch-action: manipulation;
}

.modal {
    display: none;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    /* height: 100%; */
    background-color: rgba(0, 0, 0, 0.7);

}

.modal-content {
    background-color: #FFFFFF;
    border-radius: 0.5em;
    box-shadow: 0 10px 20px rgba(black, 0.2);
    margin: 2em auto;
    padding: 1em;
    border: 1px solid #888888;
    width: 80% !important;
}

.close {
    color: #AAAAAA;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
}

.sans-serif-text {
    font-family: var(--sans-serif-font), sans-serif;
}

.jumbotron.vertical-center {
    margin-bottom: 0;
}

.vertical-center {
    position: relative;
    min-height: 100%;
    min-height: 100vh;
    width: 100%;
}

/* #customized-emoji {
    font-family: var(--emoji-font);
    font-size: 16em;
    font-palette: --palette;
}

#result-canvas {
    font-palette: --palette;
} */

.footer-content {
    font-size: 10pt;
    font-family: var(--monospaced-font), monospace;
}

.color-block {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 3em;
    height: 3em;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 0.1em
}

.color-block::-webkit-color-swatch {
    border-radius: 10px;
    border: 1px solid #000000;
}

.color-block::-moz-color-swatch {
    border-radius: 10px;
    border: 1px solid #000000;
}

.rect-btn {
    width: 13em;
    height: 2.5em;
    font-size: 1em;
}

.square-btn {
    width: 2.5em;
    height: 2.5em;
    font-size: 1em;
}

.basic-btn {
    border: none;
    border-radius: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
}

#color-pickers {
    align-items: center;
    justify-content: center;
    line-height: 0
}

#emoji-picker-mobile {
    position: fixed;
    left: 50%;
    top: 45%;
    -ms-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.clr-component {
    margin: 0.2em;
}

.clr-component .clr-field button {
    width: 100%;
    height: 100%;
    border-radius: 0.5em;
    border: 1px solid black;
}

.clr-component .clr-field button:after {
    border-radius: none !important;
}

.desktop-picker-input {
    width: 3em;
    height: 3em;
    border-radius: 0.5em;
    box-sizing: border-box;
    cursor: pointer;
}

.btn-icon {
    width: 1.5em;
    height: 1.5em;
    fill: currentColor;
    transition: 0.2s;
}

.btn-hover-color-1:hover {
    color: #3b5998
}

.btn-hover-color-2:hover {
    color: #55acee
}

.btn-hover-color-3:hover {
    color: #dd4b39
}

.btn-hover-color-4:hover {
    color: #bd081c
}

.btn-hover-color-5:hover {
    color: #833ab4
}

.btn-hover-color-6:hover {
    color: #FFB11B
}

.btn-hover-color-1:hover::after {
    background: #3b5998
}

.btn-hover-color-2:hover::after {
    background: #55acee
}

.btn-hover-color-3:hover::after {
    background: #dd4b39
}

.btn-hover-color-4:hover::after {
    background: #bd081c
}

.btn-hover-color-5:hover::after {
    background: #833ab4
}

.btn-hover-color-6:hover::after {
    background: #DDA52D
}

.home-btn {
    position: relative;
    color: #333333;
    background-color: #FFFFFF;
    box-shadow: var(--shadow);
}

.share-btn {
    position: relative;
    background-color: #444444;
    color: #FFFFFF;
    box-shadow: var(--shadow);
}

.square-btn:not(:last-of-type) {
    margin-right: 15px;
}

.btn-tooltip::before,
.btn-tooltip::after {
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -10px);
    opacity: 0;
    transition: 0.2s;
}

.btn-tooltip::after {
    content: attr(aria-label);
    font-family: var(--sans-serif-font), sans-serif;
    width: 12em;
    padding: .61em .93em;
    font-size: 0.8em;
    color: #FFFFFF;
    border-radius: 3px;
}

.mobile-icon-desciption::after {
    content: attr(aria-label);
    font-family: var(--sans-serif-font), sans-serif;
    top: calc(100% + 10px);
    position: absolute;
    font-size: 0.8em;
    color: var(--dark-background-color);
    border-radius: 3px;
}

.home-btn::after {
    /* Tooltip from top */
    top: calc(100% + 10px);
}

.share-btn::after {
    /* Tooltip from buttom */
    bottom: calc(100% + 10px);
}

.btn-tooltip:hover::before,
.btn-tooltip:hover::after {
    opacity: 1;
    transform: translate(-50%);
}

/* Source Code  Theme */
.code-area-background {
    white-space: pre-wrap;
    background: #dddddd;
    line-height: 1rem;
    border-radius: 5pt;
    padding: 10pt;
}

.underline {
    text-decoration-line: underline;
    text-decoration-color: #d6d3d1;
}

.emoji-mart,
.emoji-mart * {
    box-sizing: border-box;
    line-height: 1.15
}

.emoji-mart {
    font-family: var(--sans-serif-font),  sans-serif;
    font-size: 16px;
    display: inline-block;
    color: #222427;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    background: #fff
}

.emoji-mart .emoji-mart-emoji {
    padding: 6px
}

.emoji-mart-emoji {
    position: relative;
    display: inline-block;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: none;
    background: 0 0;
    box-shadow: none
}

.emoji-mart-title span {
    display: inline-block;
    vertical-align: middle
}

.emoji-mart-title .emoji-mart-emoji {
    padding: 0
}

.emoji-mart-title-label {
    font-family: var(--sans-serif-font),  sans-serif;
    color: #999a9c;
    font-size: 26px;
    font-weight: 300
}
