@import "https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap";
@import "https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,500&display=swap";
:root {
  --shadow-color: 0deg 0% 0%;
  --shadow: .1px .5px 2.7px hsl(var(--shadow-color) / .14), .4px .8px 1px -3.2px hsl(var(--shadow-color) / .14), 1px 2px 2.5px -4.5px hsl(var(--shadow-color) / .14);
  --default-background-color: #eee;
  --dark-background-color: #222;
  --sans-serif-font: "Nunito Sans";
  --monospaced-font: "Courier Prime";
}

body {
  background-color: #e1e1e1 !important;
}

html, body {
  touch-action: manipulation;
}

.modal {
  z-index: 10;
  background-color: #000000b3;
  width: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-content {
  box-shadow: 0 10px 20px rgba(black, .2);
  background-color: #fff;
  border: 1px solid #888;
  border-radius: .5em;
  margin: 2em auto;
  padding: 1em;
  width: 80% !important;
}

.close {
  color: #aaa;
  float: right;
  cursor: pointer;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

.sans-serif-text {
  font-family: var(--sans-serif-font), sans-serif;
}

.jumbotron.vertical-center {
  margin-bottom: 0;
}

.vertical-center {
  width: 100%;
  min-height: 100vh;
  position: relative;
}

.footer-content {
  font-size: 10pt;
  font-family: var(--monospaced-font), monospace;
}

.color-block {
  appearance: none;
  cursor: pointer;
  background-color: #0000;
  border: none;
  width: 3em;
  height: 3em;
  margin: .1em;
}

.color-block::-webkit-color-swatch {
  border: 1px solid #000;
  border-radius: 10px;
}

.color-block::-moz-color-swatch {
  border: 1px solid #000;
  border-radius: 10px;
}

.rect-btn {
  width: 13em;
  height: 2.5em;
  font-size: 1em;
}

.square-btn {
  width: 2.5em;
  height: 2.5em;
  font-size: 1em;
}

.basic-btn {
  border: none;
  border-radius: .5em;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0 !important;
}

#color-pickers {
  justify-content: center;
  align-items: center;
  line-height: 0;
}

#emoji-picker-mobile {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.clr-component {
  margin: .2em;
}

.clr-component .clr-field button {
  border: 1px solid #000;
  border-radius: .5em;
  width: 100%;
  height: 100%;
}

.clr-component .clr-field button:after {
  border-radius: none !important;
}

.desktop-picker-input {
  box-sizing: border-box;
  cursor: pointer;
  border-radius: .5em;
  width: 3em;
  height: 3em;
}

.btn-icon {
  fill: currentColor;
  width: 1.5em;
  height: 1.5em;
  transition: all .2s;
}

.btn-hover-color-1:hover {
  color: #3b5998;
}

.btn-hover-color-2:hover {
  color: #55acee;
}

.btn-hover-color-3:hover {
  color: #dd4b39;
}

.btn-hover-color-4:hover {
  color: #bd081c;
}

.btn-hover-color-5:hover {
  color: #833ab4;
}

.btn-hover-color-6:hover {
  color: #ffb11b;
}

.btn-hover-color-1:hover:after {
  background: #3b5998;
}

.btn-hover-color-2:hover:after {
  background: #55acee;
}

.btn-hover-color-3:hover:after {
  background: #dd4b39;
}

.btn-hover-color-4:hover:after {
  background: #bd081c;
}

.btn-hover-color-5:hover:after {
  background: #833ab4;
}

.btn-hover-color-6:hover:after {
  background: #dda52d;
}

.home-btn {
  color: #333;
  box-shadow: var(--shadow);
  background-color: #fff;
  position: relative;
}

.share-btn {
  color: #fff;
  box-shadow: var(--shadow);
  background-color: #444;
  position: relative;
}

.square-btn:not(:last-of-type) {
  margin-right: 15px;
}

.btn-tooltip:before, .btn-tooltip:after {
  opacity: 0;
  transition: all .2s;
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -10px);
}

.btn-tooltip:after {
  content: attr(aria-label);
  font-family: var(--sans-serif-font), sans-serif;
  color: #fff;
  border-radius: 3px;
  width: 12em;
  padding: .61em .93em;
  font-size: .8em;
}

.mobile-icon-desciption:after {
  content: attr(aria-label);
  font-family: var(--sans-serif-font), sans-serif;
  color: var(--dark-background-color);
  border-radius: 3px;
  font-size: .8em;
  position: absolute;
  top: calc(100% + 10px);
}

.home-btn:after {
  top: calc(100% + 10px);
}

.share-btn:after {
  bottom: calc(100% + 10px);
}

.btn-tooltip:hover:before, .btn-tooltip:hover:after {
  opacity: 1;
  transform: translate(-50%);
}

.code-area-background {
  white-space: pre-wrap;
  background: #ddd;
  border-radius: 5pt;
  padding: 10pt;
  line-height: 1rem;
}

.underline {
  text-decoration-line: underline;
  text-decoration-color: #d6d3d1;
}

.emoji-mart, .emoji-mart * {
  box-sizing: border-box;
  line-height: 1.15;
}

.emoji-mart {
  font-family: var(--sans-serif-font), sans-serif;
  color: #222427;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  font-size: 16px;
  display: inline-block;
}

.emoji-mart .emoji-mart-emoji {
  padding: 6px;
}

.emoji-mart-emoji {
  box-shadow: none;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.emoji-mart-title span {
  vertical-align: middle;
  display: inline-block;
}

.emoji-mart-title .emoji-mart-emoji {
  padding: 0;
}

.emoji-mart-title-label {
  font-family: var(--sans-serif-font), sans-serif;
  color: #999a9c;
  font-size: 26px;
  font-weight: 300;
}

/*# sourceMappingURL=index.f2739738.css.map */
